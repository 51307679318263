import { useContext } from "react";
import Footer from "../Components/Footer";
import FreshMilk from "../Components/FreshMilk";
import GetFreshMilk from "../Components/GetFreshMilk";
import Header from "../Components/Header";
import HeroSection from "../Components/HeroSection";
import Newsletter from "../Components/Newsletter";
import Testimonials from "../Components/Testimonials";
import WhyChooseUs from "../Components/WhyChooseUs";
import { DarkModeContext } from "../DarkModeContext";
import { primaryDarkColor } from "../../constant";
import { Helmet } from 'react-helmet';

export default function Home() {

    const { darkMode } = useContext(DarkModeContext);

    return (
        <>
<Helmet>
<title>Fresh Milk Delivery in Bangalore | Organic A2 Milk | MilkPromise</title>
<meta
name="description"
content="MilkPromise delivers farm-fresh A2 cow milk to homes in Bangalore, Billapura,
Sarjapura, and Sompura Gate. Subscribe for healthy, organic dairy."
/>
<meta
name="keywords"
content="Fresh Milk Delivery Bangalore, Organic A2 Milk Bangalore, Cow Milk Home Delivery
Bangalore, Fresh Milk Delivery in Billapura, Sarjapura, Sompura Gate"
/>
</Helmet>

            <Header />
            <HeroSection />
            <FreshMilk />
            <GetFreshMilk />
            <WhyChooseUs />
            <div style={{ padding: '3% 0', background: darkMode ? primaryDarkColor : '' }}><Testimonials /></div>
            <Newsletter />
            <Footer />
        </>
    )
}